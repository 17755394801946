<template>
  <div id="outsideContainer">
    <div class="card-wrapper">
      <div class="card-content">
        <div class="card-items">
          <div class="count-down-text">
            <voc-count-down
              ref="vocCountDown"
              @on-count-down-finished="handleCountDownFinish"
            />
          </div>
          <div class="test-item">
            <audio-to-response-recorder
              ref="audioToTextRecorder"
              @on-recording-btn-click="($event) => (isRecording = $event)"
              @on-recording-start="handleRecordingStart"
              @on-recording-end="handleRecordingEnd"
              @audio-to-text="handleAudioToText($event)"
            />
          </div>

          <div class="test-item audio-text"></div>
        </div>
      </div>
    </div>
    <!-- <div class="next-btn">
      <el-button type="primary" round @click="handleNextClick">NEXT</el-button>
    </div> -->

    <div>
      <audio ref="vocaudio"></audio>
    </div>
  </div>
</template>

<script>
import AudioToResponseRecorder from "../recordtotest/SpeakingRecordingCard.vue";
import VocCountDown from "../recordtotest/VocCountDown.vue";
export default {
  name: "Speaking",
  components: { AudioToResponseRecorder, VocCountDown },
  data() {
    return {
      audioText: "",
      isRecording: false
    };
  },

  watch: {},

  beforeMount: function () {},
  mounted: function () {
    document.addEventListener("keyup", this.handleAudioTestKeyUp);
    //set background color
    document
      .querySelector("body")
      .setAttribute("style", "background-color: rgb(246, 247, 251)");

    //初始化用户角色
    this.userRole = localStorage.getItem("userRole");
  },

  beforeUnmount: function () {
    document.removeEventListener("keyup", this.handleAudioTestKeyUp); // remove global key down event before leaving voc page
  },

  methods: {
    handleAudioToText: function (response) {
      //把音频的文本设定成学生的答案
      this.testItem.studentAnswers[
        this.currentTestingVocIndex
      ].textConvertedByAudio = response.data.join("，");
      //把音频的base64str传入当前单词的base64str
      this.testItem.studentAnswers[this.currentTestingVocIndex].audioBase64str =
        response.base64Str;
      //赋值currentTestVoc之前判定isCorrect的值
      this.isAudioTextAnswerCorrect(response.data);

      //转成文本后，在这里提交答案，1秒后执行next
      //setTimeout(() => this.implementNextBtnClick(), 1000);
      this.implementNextBtnClick();
    },

    handleRecordingStart: function () {
      this.$refs.vocCountDown.countdown(45, true);
    },

    handleRecordingEnd: function () {
      this.$refs.vocCountDown.countdown(0, false);
    },

    handleCountDownFinish: function () {
      //倒计时结束先进入下个单词；进入之前需要录音识别和答案提交
      //录音停止，提交答案、to be continued

      //进入下个单词
      //this.handleNextClick();
    },

    submit: function () {},

    handleAudioTestKeyUp: function (event) {
      //回车，提前当前回答
      switch (event.keyCode) {
        case 13:
          //进入下个单词
          this.handleNextClick();
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div#outsideContainer {
  font: bold 24pt sans;
  color: deepskyblue;
}

.test-progress {
  font: 500 18pt sans;
}

.card-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.card-wrapper > * {
  flex: 1 100%;
}

.card-content {
  border-radius: 5px 5px 0 0;
  margin-left: 19%;
  margin-right: 19%;
  background-color: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(48, 53, 69, 0.08);
  height: 400px;
}

.card-items {
  margin-top: 1.5rem;
}

.next-btn {
  margin-top: 2rem;
}

.test-audio-icon {
  cursor: pointer;
  color: orange;
}

.test-item {
  margin-top: 2rem;
}

.count-down-text {
  font: bold 28pt sans;
}

.test-listening-count {
  font: 100 12pt sans;
}

.audio-text {
  color: rgb(189, 122, 246, 0.8);
  font: 600 20pt sans;
}
</style>
