import axios from "axios";

export default {
  //post
  getElsaResponse(context, data) {
    return new Promise((resolve, reject) => {
      let postUrl = "https://api.elsanow.io/api/v1/score_audio_plus";
      axios
        .post(postUrl, data, {
          headers: {
            Authorization: `Bearer DX+gsaRdJgTQN7K3XtShZ5b6x/KnAn7pa7QPf777QVF3dqimTPzPb8A0KqEU1e7HMblCS+cJEqO8jThlMQVB2cV98dhLfWa7aoSrKUeRJIE=`
          }
        })
        .then((response) => {
          console.log(response)
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
