<template>
  <div class="login-container">
    <el-form
      :model="userLoginForm"
      :rules="userLoginFormRules"
      status-icon
      ref="userLoginForm"
      label-position="left"
      label-width="0px"
      class="demo-ruleForm login-page"
    >
      <el-form-item prop="username">
        <el-input
          type="text"
          v-model="userLoginForm.username"
          auto-complete="off"
          placeholder="用户名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          v-model="userLoginForm.password"
          auto-complete="off"
          placeholder="密码"
        ></el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          type="primary"
          style="width: 100%"
          @click="handleSubmit"
          :loading="logining"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "Login",
  components: {
    
  },
  data() {
    return {
      logining: false,

      userLoginForm: {
        username: "",
        password: "",
      },
      userLoginFormRules: {
        username: [
          {
            required: true,
            message: "please enter your account",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "enter your password", trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    document.addEventListener("keydown", this.handleKeyDown, true);
  },
  beforeUnmount: function () {
    console.log("before destroy in Login");
    document.removeEventListener("keydown", this.handleKeyDown, true);
  },
  methods: {
    handleSubmit() {
      this.$refs.userLoginForm.validate((valid) => {
        if (valid) {
          this.logining = true;
          this.$store
            .dispatch("Login/login", {
              username: this.userLoginForm.username,
              password: this.userLoginForm.password,
            })
            .then((response) => {
              console.log(response)
              this.$router.push("/");
              this.logining = false;
              // if (localStorage.getItem("userRole") == "Student") {
              //   this.$router.push("/studentHome");
              // } else {
              //   this.$router.push("/");
              // }
            })
            .catch((error) => {
              this.$message(error.toString());
              this.$message("登录出错，请检查用户名密码是否正确～");
              this.logining = false;
            });
          // axios
          //   .get(currentUrl, {
          //     params: {
          //       username: _localThis.userLoginForm.username,
          //       password: _localThis.userLoginForm.password
          //     }
          //   })
          //   .then(function(response) {
          //     _localThis.LoginValidation(response.data);
          //     document.removeEventListener("keydown", this.handleKeyDown, true); // remove event listener after loginning in
          //   })
          //   .catch(function() {
          //     _localThis.loginFail();
          //   });
        } else {
          this.loginFail();
          return false;
        }
      });
    },

    handleKeyDown: function (event) {
      if (event.keyCode === 13) {
        this.handleSubmit();
      }
    },

    // LoginValidation: function (data) {
    //   if (data.Enabled === true) {
    //     this.logining = false;
    //     sessionStorage.setItem("userId", data.UserId);
    //     sessionStorage.setItem("userName", data.UserName);
    //     sessionStorage.setItem("userCNName", data.ChineseName);
    //     sessionStorage.setItem("targetId", data.UserId);
    //     sessionStorage.setItem("userRole", data.RoleName);
    //     sessionStorage.setItem("enabled", data.Enabled);
    //     this.$router.push({ path: "/" });
    //   } else {
    //     this.logining = false;
    //     this.$message("username/password - Oops!");
    //   }
    // },

    loginFail: function () {
      this.logining = false;
      //this.$message("Login Failed - Oops!");
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  background: url("../../assets/andover-bg.jpg") no-repeat;
  background-size: cover;
  position: fixed;
  margin: 0 auto;

}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 20% auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>