<template>
  <div id="outsideContainer" class="border">
    <div class="card-wrapper">
      <el-button
        class="mt-4"
        style="width: 60%"
        :type="butType"
        @click="handleRecordingBtnClick"
        >{{ isRecording ? "正在录音中...请说话..." : "录音已停止" }}</el-button
      >
    </div>

    <div>
      <audio ref="vocaudio"></audio>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpeakingRecordingCard",
  components: {},
  props: [],

  data() {
    return {
      rec: null,
      audioChunks: [],
      isRecording: false,
      butType: "",
      isRecordingCardInitialized: false
    };
  },

  watch: {},

  beforeMount: function () {},

  mounted: function () {
    //this.initializeAudioRecording();
  },

  methods: {
    handleRecordingBtnClick: function () {
      if(!this.isRecordingCardInitialized) {
        this.initializeAudioRecording();
        this.isRecordingCardInitialized = true;
      }
      this.isRecording = !this.isRecording;
      //把isRecording的值传出去，一遍父组件能知道当前播放的状态
      this.$emit("on-recording-btn-click", this.isRecording);
      this.butType = this.isRecording ? "danger" : "";
      if (this.isRecording) {
        this.handleStartRecording();
      } else {
        this.handleStopRecording();
      }
    },

    handleStartRecording: function () {
      // if (this.isRecording) {
      //   this.initializeAudioRecording();
      // }
      this.audioChunks = [];
      if (this.rec) this.rec.start();
      //当录音开始的时候，使其允许手动点击结束录音
      //this.disabled = false;
      //this.getHuaweiCloudToken();
      this.$emit("on-recording-start");
    },

    handleStopRecording: function () {
      if (this.rec) this.rec.stop();
      //当录音结束，或者手动点击录音结束的时候，不能再次手动点击开启录音
      //this.disabled = true;
      this.$emit("on-recording-end");
    },

    initializeAudioRecording: function () {
      let _this = this;
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        handlerFunction(stream);
        _this.handleRecordingBtnClick();
      });
      function handlerFunction(stream) {
        _this.rec = new MediaRecorder(stream);
        _this.rec.ondataavailable = (e) => {
          _this.audioChunks.push(e.data);
          if (_this.rec.state == "inactive") {
            let blob = new Blob(_this.audioChunks, { type: "audio/mpeg-3" });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;
              let base64result = base64String.split("base64,")[1];
              console.log(base64result);
              _this.getElsaResponse(base64result)
              //_this.convertAudioToText(base64result);
            };
            _this.$refs.vocaudio.src = URL.createObjectURL(blob);
            //sendData(blob)
          }
        };
      }
    },

    getElsaResponse: function (base64Str) {
      this.$store
        .dispatch("Elsa/getElsaResponse", {
          api_plan: "premium",
          return_json: true,
          audio_data: base64Str,
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error.toString());
        });
    },
  },

  beforeUnmount: function () {},
};
</script>
<style scoped></style>
