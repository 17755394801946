<template>
  <div>
    <el-dialog
      width="50%"
      v-model="dialogFormVisible"
      title="创建日常背诵任务"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-form-item label="错误">
        <el-input-number v-model="form.incorrectCount" size="small" />
      </el-form-item>

      <el-form-item label="检查">
        <el-input-number v-model="form.checkedCount" size="small" />
      </el-form-item>

      <el-form-item label="错词编辑">
        <el-input v-model="form.incorrects" />
      </el-form-item>

      <el-form-item label="生成结果">
        <el-input v-model="form.generatedResult" />
      </el-form-item>

      <el-form-item label="耗时(分)">
        <el-input-number
          v-model="form.timeConsumed"
          :precision="2"
          size="small"
        />
      </el-form-item>

      <el-form-item label="过关">
        <el-switch
          v-model="form.passed"
          active-color="#13ce66"
          inactive-color="gray"
        />
      </el-form-item>

      <div style="text-align: left">
        <span>评价</span>
      </div>
      <quill-editor v-model:value="form.evaluation" />

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="onCreatePlanFormDetailTaskResultCancel()"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            @click="onCreatePlanFormDetailTaskResultConfirm()"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CreatePlanDetailTaskResultForm",
  components: {},

  props: ["modelValue", "dataFromCard"],

  watch: {
    modelValue: function () {
      this.dialogFormVisible = this.modelValue;
    },
  },

  mounted: function () {},

  data() {
    return {
      dialogFormVisible: false,

      form: {
        incorrectCount: 0,
        checkedCount: 0,
        incorrects: "", //哪些词错误，以及助教写的文本
        generatedResult: "", //系统copy生产的检查结果
        timeConsumed: 0.0, //:precision="2"
        evaluation: "",
        passed: false,
      },
    };
  },

  methods: {
    onCreatePlanFormDetailTaskResultCancel: function () {
      this.dialogFormVisible = false;
      this.$emit("on-plan-form-detail-task-result-cancel");
    },
    onCreatePlanFormDetailTaskResultConfirm: function () {
      let postData = this.form;
      postData.createdBy = localStorage.getItem("userId");
      postData.updatedBy = localStorage.getItem("userId");
      postData.studentPlanDetailTaskId = this.dataFromCard.taskInfo.taskId;
      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlanDetailTaskResult", this.form)
        .then((response) => {
          if (response.data > 0) {
            this.$message({
              type: "success",
              message: "结果已提交～",
            });
            this.$emit("on-plan-form-detail-task-result-confirm", this.dataFromCard);

            //确认结果之后，post一个isExaminingAsStudent的状态修改
            this.$store
              .dispatch(
                "StudentPlan/updateStudentPlanDetailTaskIsExaminingAsStudent",
                {
                  taskId: this.dataFromCard.taskInfo.taskId,
                  isExaminingAsStudent: false,
                }
              )
              .then((response) => {
                console.log(response.data);
              });

            this.dialogFormVisible = false;
          }
        });
    },

    handleOpen: function () {
      if (this.dataFromCard.resultInfo) {
        this.form.incorrectCount = this.dataFromCard.resultInfo.incorrectCount;
        this.form.checkedCount = this.dataFromCard.resultInfo.checkedCount;
        this.form.incorrects = this.dataFromCard.resultInfo.incorrects;
        this.form.generatedResult =
          this.dataFromCard.resultInfo.generatedResult;
        this.form.timeConsumed = this.dataFromCard.resultInfo.timeConsumed;
        this.form.passed = this.isPassed();
      }
    },

    isPassed: function () {
      if (this.form.checkedCount <= 0) return;
      let correctRate = Math.ceil(
        ((this.dataFromCard.resultInfo.checkedCount -
          this.dataFromCard.resultInfo.incorrectCount) /
          this.dataFromCard.resultInfo.checkedCount) *
          100
      );
      return correctRate >= this.dataFromCard.taskInfo.criterion ? true : false;
    },

    handleClose: function () {
      // console.log('aaaa')
      this.$emit("on-plan-form-daily-task-result-close");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cursor {
  cursor: pointer;
}
</style>
