import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import DIY from "../components/DIY.vue";
import Login from "../components/login/Login.vue";
import Card from "../components/voc/Card.vue";
import BindStudent from "../components/admin/TeacherBindStudent.vue";
import StudentTest from "../components/voc/test/index.vue";
import TestItem from "../components/voc/test/TestItem.vue";
import NewTestItem from "../components/voc/test/NewTestItem.vue";
import NewTestItemResult from "../components/voc/test/NewTestItemResult.vue";
import AssignToStudent from "../components/voc/vip/AssignToStudent.vue";
import StudentVocPracticeList from "../components/voc/vip/StudentVocPracticeList.vue";
import StudentVocPracticeItem from "../components/voc/vip/StudentVocPracticeItem.vue";
import CheckStudentAssignment from "../components/voc/vip/CheckStudentAssignment.vue";
import StudentVocNirvanaItem from "../components/voc/vip/StudentVocNirvanaItem.vue";
import StudentOverview from "../components/voc/studentoverview/index.vue";
import StudentSubjectOverview from "../components/voc/studentoverview/subject.vue";
import RecordingCard from "../components/voc/recordtotest/RecordingCard.vue";
import AudioTestItem from "../components/voc/test/AudioTestItem.vue";
import AudioTestItemResult from "../components/voc/test/AudioTestItemResult.vue";
import Plan from "../components/voc/plan/index.vue";
import Speaking from "../components/voc/speaking/Speaking.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Andover Vocabulary - Home",
    },
  },
  {
    path: "/DIY",
    name: "DIY",
    component: DIY,
    meta: {
      title: "Andover Vocabulary - DIY",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/card",
    name: "Card",
    component: Card,
    meta: {
      title: "Andover Vocabulary - 单词卡",
    },
  },
  {
    path: "/bindstudent",
    name: "BindStudent",
    component: BindStudent,
  },
  {
    path: "/studenttest",
    name: "StudentTest",
    component: StudentTest,
  },
  {
    path: "/testitem",
    name: "TestItem",
    component: TestItem,
  },
  {
    path: "/newtestitem",
    name: "NewTestItem",
    component: NewTestItem,
  },
  {
    path: "/newtestitemresult",
    name: "NewTestItemResult",
    component: NewTestItemResult,
  },
  {
    path: "/assigntostudent",
    name: "AssignToStudent",
    component: AssignToStudent,
  },
  {
    path: "/vPracticeList",
    name: "StudentVocPracticeList",
    component: StudentVocPracticeList,
  },
  {
    path: "/vPracticeItem",
    name: "StudentVocPracticeItem",
    component: StudentVocPracticeItem,
  },
  {
    path: "/checkstudentassignment",
    name: "CheckStudentAssignment",
    component: CheckStudentAssignment,
  },
  {
    path: "/vNirvanaItem",
    name: "StudentVocNirvanaItem",
    component: StudentVocNirvanaItem,
  },
  {
    path: "/studentOverview",
    name: "StudentOverview",
    component: StudentOverview,
  },
  {
    path: "/studentSubjectOverview",
    name: "StudentSubjectOverview",
    component: StudentSubjectOverview,
  },
  {
    path: "/recordingcard",
    name: "RecordingCard",
    component: RecordingCard,
  },
  {
    path: "/audiotestitem",
    name: "AudioTestItem",
    component: AudioTestItem,
  },
  {
    path: "/audiotestitemresult",
    name: "AudioTestItemResult",
    component: AudioTestItemResult,
  },
  {
    path: "/plan",
    name: "Plan",
    component: Plan,
  },
  {
    path: "/speaking",
    name: "Speaking",
    component: Speaking,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
